import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { IoIosStar, IoIosStarOutline } from "react-icons/io";

import { useAddProcessFavoriteMutation } from "../../store/services/processesApi";

export default function Star({ ...props }) {
  const [favorite, setFavorite] = useState(props?.process?.is_favorite);

  const [setProcessFavorite] = useAddProcessFavoriteMutation();

  const handleFavorite = useCallback(() => {
    const response = setProcessFavorite({
      id: props?.process?.id,
    });

    response.then((res) => {
      if (Object.keys(res).includes("error")) {
        return toast.error("Não foi possível remover favorito");
      }
      return setFavorite(!favorite);
    });
  }, [favorite, props?.process?.id, setProcessFavorite]);

  return (
    <span onClick={handleFavorite}>
      {favorite ? (
        <IoIosStar
          className="text-2xl md:text-3xl cursor-pointer"
          color="#FFE600"
        />
      ) : (
        <IoIosStarOutline className="text-2xl md:text-3xl cursor-pointer" />
      )}
    </span>
  );
}
