import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "antd";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import {
  pjeApi,
  useDeleteCredentialsPJEMutation,
  useGetCredentialsPJEQuery,
  useValidateCredentialsPJEMutation,
} from "../../../store/services/pjeApi";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

const schema = z.object({
  usernamePje: z.string().min(1, "Usuário é obrigatório"),
  passwordPje: z.string().min(1, "Senha é obrigatório"),
  id: z.any().optional(),
});

export default function Credential() {
  const { data: credentialsData } = useGetCredentialsPJEQuery();

  const [validateCredentialPJE] = useValidateCredentialsPJEMutation();
  const [deleteCredentials] = useDeleteCredentialsPJEMutation();

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: zodResolver(schema),
    values: {
      usernamePje: credentialsData?.usernamePje,
      passwordPje: credentialsData?.passwordPje,
      id: credentialsData?.id,
    },
    defaultValues: {
      usernamePje: "",
      passwordPje: "",
      id: null,
    },
  });

  const handleFormSubmit = useCallback(
    (data) => {
      const response = validateCredentialPJE(data);

      response.then((response) => {
        if (Object.keys(response).includes("error")) {
          toast.error("Erro ao criar validar credenciais");
        } else {
          toast.success("Credenciais validada com sucesso!");
        }
      });
    },
    [validateCredentialPJE]
  );

  const handleDeleteCredential = useCallback(() => {
    if (credentialsData?.id) {
      const response = deleteCredentials(credentialsData?.id);

      response.then((response) => {
        if (Object.keys(response).includes("error")) {
          toast.error("Erro ao remover credenciais");
        } else {
          toast.success("Credenciais removida com sucesso!");
          dispatch(pjeApi.util.resetApiState(["PJE"]));
          reset();
        }
      });
    }
  }, [credentialsData?.id, deleteCredentials, dispatch, reset]);

  return (
    <div className="w-full p-4 flex flex-col shadow-login gap-7">
      <span className="text-2xl font-semibold underline underline-offset-8">
        Credencial PJE
      </span>
      <div className="flex flex-col 2xl:w-[70%] 3xl:w-[55%] gap-3">
        <span className="border border-[#BBBBBB] p-4 bg-[#F3F3F3] rounded-lg text-center md:text-start ">
          É necessário conectar suas credenciais de acesso ao PJE para trazer os
          processos. Os processos não são armazenados pela Biofy Technologies;
          eles são processados diretamente na nuvem, e apenas o resultado
          compilado é exibido.
        </span>
        <form
          className="w-full grid grid-flow-row-dense grid-cols-2 gap-5"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div className="col-span-2">
            <span className="text-[#5C5C5C] text-sm font-normal">Usuário</span>
            <Controller
              control={control}
              name="usernamePje"
              render={({ field }) => (
                <Input
                  size="large"
                  {...field}
                  status={errors.usernamePje ? "error" : ""}
                  placeholder="Insira o seu usuário"
                  disabled={credentialsData}
                  className={`${
                    errors?.usernamePje
                      ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                      : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                  } w-full rounded-sm `}
                />
              )}
            />
            {errors?.usernamePje && (
              <span className="text-[#fe3535] text-sm">
                {errors?.usernamePje.message}
              </span>
            )}
          </div>{" "}
          <div className="col-span-2">
            <span className="text-[#5C5C5C] text-sm font-normal">Senha</span>
            <Controller
              control={control}
              name="passwordPje"
              render={({ field }) => (
                <Input.Password
                  size="large"
                  {...field}
                  status={errors.passwordPje ? "error" : ""}
                  placeholder="Insira a senha"
                  disabled={credentialsData}
                  className={`${
                    errors?.passwordPje
                      ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                      : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                  } w-full rounded-sm `}
                />
              )}
            />
            {errors?.passwordPje && (
              <span className="text-[#fe3535] text-sm">
                {errors?.passwordPje.message}
              </span>
            )}
          </div>{" "}
          {!credentialsData && (
            <div className="col-span-2 flex justify-center">
              <Button
                size="large"
                htmlType="submit"
                className="text-white bg-second hover:opacity-70 hover:border-[#212121] font-semibold rounded px-6"
              >
                CONECTAR
              </Button>
            </div>
          )}
        </form>{" "}
        {credentialsData && (
          <div className="col-span-2 flex justify-center">
            <Button
              size="large"
              htmlType="button"
              onClick={handleDeleteCredential}
              className="text-white bg-second hover:opacity-70 hover:border-[#212121] font-semibold rounded px-6"
            >
              DESCONECTAR
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
