import React, { useMemo } from "react";
import { FaRegFaceSmile } from "react-icons/fa6";
import { FiCreditCard, FiLock } from "react-icons/fi";
import { AiOutlineTool } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import ConfigPages from "../../components/Configs";
import { useAppSelector } from "../../store/hooks";

const version = process.env.REACT_APP_TAG_VERSION;

const listConfigurations = [
  {
    id: 0,
    name: "Meu Perfil",
    link: "/config/profile",
    icon: <FaRegFaceSmile size={22} />,
  },
  {
    id: 1,
    name: "Configurações do sistema",
    link: "/config/system",
    icon: <AiOutlineTool size={24} />,
  },
  {
    id: 2,
    name: "Credencial PJE",
    link: "/config/credencial",
    icon: <FiLock size={22} />,
  },
  {
    id: 3,
    name: "Pagamento",
    link: "/config/payment",
    icon: <FiCreditCard size={22} />,
  },
];

export default function Config() {
  const { handle } = useParams();
  const navigate = useNavigate();

  const { userInfo } = useAppSelector((state) => {
    return {
      userInfo: state.userSlice,
    };
  });

  const listOptions = useMemo(() => {
    const hasAdmin =
      userInfo?.userData?.is_admin || userInfo?.userData?.is_superuser;

    if (version === "0.0.4") {
      return hasAdmin
        ? listConfigurations
        : listConfigurations?.filter((obj) => obj.id !== 1);
    } else {
      const newList = listConfigurations.filter((obj) => obj.id <= 2);

      return hasAdmin ? newList : newList.filter((obj) => obj.id !== 1);
    }
  }, [userInfo?.userData?.is_admin, userInfo?.userData?.is_superuser]);

  return (
    <div className="w-full block px-4 py- md:p-2 xl:p-5 relative ">
      <div className="w-full flex flex-wrap">
        <div className="py-4 flex flex-wrap gap-2 w-full justify-between">
          <div className="flex gap-2 items-center font-semibold text-2xl leading-6 mb-5">
            <span className="flex h-7 p-1 bg-primary" />
            Configurações
          </div>
        </div>
      </div>
      <div className="w-full flex pb-16 md:pb-0 flex-wrap gap-8 xl:flex-nowrap">
        <div className="w-full md:w-auto h-full flex flex-col shadow-login ">
          {listOptions.map((item, index) => (
            <div
              key={item.id}
              className={`w-full md:w-auto flex items-center select-none ${
                index === 0
                  ? "rounded-t-md"
                  : index === listConfigurations[listConfigurations.length - 1]
                  ? "rounded-b-md"
                  : ""
              } ${
                item.link.includes(handle) || (!handle && index === 0)
                  ? "border-s-[10px] border-s-primary bg-custom-gradient text-white"
                  : "border-b-[0.46px] border-[#A1A1A1]"
              } cursor-pointer`}
              onClick={() => navigate(item.link)}
            >
              <span
                className={`w-full  min-w-80 flex gap-3 px-3 text-lg p-2 py-4 items-center ${
                  !item.link.includes(handle) && "ms-[10px]"
                } `}
              >
                {item.icon} {item.name}
              </span>
            </div>
          ))}
        </div>
        <ConfigPages />
      </div>
    </div>
  );
}
