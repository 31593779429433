import { Button } from "antd";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function SuccessRegister() {
  const navigate = useNavigate();

  useEffect(() => {
    toast.success("Registo concluído com sucesso!");
  }, []);

  return (
    <div className="flex flex-col m-auto">
      <h1 className="text-[54px] text-center text-primary font-semibold">
        Bem-vindo!
      </h1>
      <div className="w-auto gap-3 p-5 flex relative flex-col rounded-2xl shadow-login px-7 max-w-[570px]">
        <div className=" flex items-center ">
          <span className="w-2 h-[31px] bg-primary me-4" />
          <span className="text-xl font-semibold !m-0">
            Cadastro realizado com sucesso.
          </span>
        </div>
        <img
          className="w-56 m-auto py-4"
          src="/img/svg/sucessRegister.svg"
          alt=""
        />
        <span className="text-sm">
          Acesse o link enviado ao seu e-mail, crie sua senha e faça login para
          aproveitar todas as funcionalidades!
        </span>
        <Button
          className="w-full px-3 py-6 text-lg bg-primary text-white font-semibold border-none hover:opacity-80 rounded-md shadow-login"
          size="large"
          onClick={() => navigate("/")}
        >
          Tela inicial
        </Button>
      </div>
    </div>
  );
}
