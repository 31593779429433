import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "antd";
import React, { useCallback, useMemo } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import toast from "react-hot-toast";
import { useUpdatePasswordUserMutation } from "../../../store/services/userApi";

const schema = z
  .object({
    password: z.string().min(1, "Senha é obrigatório"),
    repeatPassWord: z.string().min(1, "Confirmar senha é obrigatório"),
    newPassword: z.string().min(1, "Nova senha é obrigatório"),
  })
  .superRefine((fields, ctx) => {
    if (fields.newPassword !== fields.repeatPassWord) {
      ctx.addIssue({
        path: ["repeatPassWord"],
        code: z.ZodIssueCode.invalid_date,
        message: "As senha são diferentes ",
      });
    }
  });

export default function ResetPassword({ setModalOpen }) {
  const [updatePassword] = useUpdatePasswordUserMutation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: zodResolver(schema),
    defaultValues: {
      password: "",
      repeatPassWord: "",
      newPassword: "",
    },
  });

  const validatePassWord = useWatch({
    control,
    name: "newPassword",
  });

  const passWordValidate = useMemo(() => {
    const passWord = validatePassWord ?? "";

    const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(passWord);

    const hasCapitalLetters = /[A-Z]/.test(passWord);

    const hasLowercaseLetter = /[a-z]/.test(passWord);

    const hasNumbers = /\d/.test(passWord);

    const numberCharacters = passWord.length > 7;

    return {
      specialCharacters,
      hasCapitalLetters,
      hasNumbers,
      numberCharacters,
      hasLowercaseLetter,
    };
  }, [validatePassWord]);

  const handleResetPassWord = useCallback(
    (data) => {
      const response = updatePassword({
        current_password: data.password,
        new_password: data.newPassword,
      });
      void response.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error("Erro ao trocar senha");
        } else {
          reset();
          toast.success("Senha atualizada com sucesso");
          setModalOpen(false);
        }
      });
    },
    [reset, setModalOpen, updatePassword]
  );

  const verify = useCallback(() => {
    return (
      <>
        <li
          className={`${
            passWordValidate.hasNumbers ? "text-greenPassword" : "text-red-500"
          }`}
        >
          Números{" "}
        </li>
        <li
          className={`${
            passWordValidate.specialCharacters
              ? "text-greenPassword"
              : "text-red-500"
          }`}
        >
          Carácter especial
        </li>
        <li
          className={`${
            passWordValidate.hasLowercaseLetter
              ? "text-greenPassword"
              : "text-red-500"
          }`}
        >
          Letras Minúsculas
        </li>
        <li
          className={`${
            passWordValidate.hasCapitalLetters
              ? "text-greenPassword"
              : "text-red-500"
          }`}
        >
          Letras Maiúsculas
        </li>
        <li
          className={`${
            passWordValidate.numberCharacters
              ? "text-greenPassword"
              : "text-red-500"
          }`}
        >
          Mínimo de 8 caracteres
        </li>
      </>
    );
  }, [
    passWordValidate.hasCapitalLetters,
    passWordValidate.hasLowercaseLetter,
    passWordValidate.hasNumbers,
    passWordValidate.numberCharacters,
    passWordValidate.specialCharacters,
  ]);

  return (
    <div className="w-full h-full flex flex-col items-start gap-6">
      <span className="text-2xl font-semibold underline underline-offset-8">
        Trocar senha
      </span>
      <form
        onSubmit={handleSubmit(handleResetPassWord)}
        className="w-full h-full flex flex-col items-start gap-6"
      >
        <div className=" flex">
          <div className=" h-full grid grid-flow-row-dense grid-cols-2 gap-5 text-start">
            <div className="col-span-2">
              <span className="text-[#5C5C5C] text-sm font-normal">Senha</span>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <Input.Password
                    size="large"
                    {...field}
                    status={errors.user ? "error" : ""}
                    placeholder="Insira a senha atual"
                    className={`${
                      errors?.password
                        ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                        : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                    } w-full rounded-sm `}
                  />
                )}
              />
              {errors?.password && (
                <span className="w-auto text-[#fe3535] text-sm">
                  {errors?.password.message}
                </span>
              )}
            </div>{" "}
            <div className="col-span-2">
              <span className="text-[#5C5C5C] text-sm font-normal">
                Nova senha
              </span>
              <Controller
                control={control}
                name="newPassword"
                render={({ field }) => (
                  <Input.Password
                    size="large"
                    {...field}
                    status={errors.newPassword ? "error" : ""}
                    placeholder="Insira uma nova senha"
                    className={`${
                      errors?.newPassword
                        ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                        : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                    } w-full rounded-sm `}
                  />
                )}
              />
              {errors?.newPassword && (
                <span className="w-auto text-[#fe3535] text-sm">
                  {errors?.newPassword.message}
                </span>
              )}
            </div>{" "}
            <div className="col-span-2 font-bold ">
              <span>Dificuldade da senha: </span>
              {!passWordValidate.numberCharacters ? (
                <span className="text-[#fe3535]">Muito curta</span>
              ) : !passWordValidate.hasCapitalLetters ||
                !passWordValidate.hasLowercaseLetter ||
                !passWordValidate.specialCharacters ? (
                <span className="text-[#fe3535]">Muito fraca</span>
              ) : (
                <span className="text-[#38c525]">Forte</span>
              )}
            </div>{" "}
            <div className="col-span-2 font-bold md:hidden">
              <div className="flex h-auto flex-col p-4 px-6 text-start gap-2 shadow-login ">
                <strong>A senha deve conter:</strong>
                <ul>{verify()}</ul>
              </div>
            </div>
            <div className="col-span-2">
              <span className="text-[#5C5C5C] text-sm font-normal">
                Confirmar nova senha
              </span>
              <Controller
                control={control}
                name="repeatPassWord"
                render={({ field }) => (
                  <Input.Password
                    size="large"
                    {...field}
                    status={errors.repeatPassWord ? "error" : ""}
                    placeholder="Insira a senha novamente"
                    className={`${
                      errors?.repeatPassWord
                        ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                        : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                    } w-full rounded-sm `}
                  />
                )}
              />
              {errors?.repeatPassWord && (
                <span className="w-auto text-[#fe3535] text-sm">
                  {errors?.repeatPassWord.message}
                </span>
              )}
            </div>{" "}
          </div>{" "}
          <div
            className={`hidden h-full md:flex m-auto relative ${
              passWordValidate?.hasCapitalLetters ||
              passWordValidate?.hasLowercaseLetter ||
              passWordValidate?.hasNumbers ||
              passWordValidate?.numberCharacters ||
              passWordValidate?.specialCharacters ||
              errors?.repeatPassWord ||
              errors?.newPassword ||
              errors?.password
                ? "pb-10"
                : "pb-4"
            }   min-w-72`}
          >
            <svg
              width="50"
              height="100"
              xmlns="http://www.w3.org/2000/svg"
              className="h-full m-auto !mr-[-1px] z-20"
            >
              <defs>
                <filter id="f1" x="0" y="0" width="150%" height="150%">
                  <feDropShadow
                    dx="3"
                    dy="3"
                    stdDeviation="6"
                    flood-color="rgba(0,0,0,0.5)"
                  />
                </filter>
              </defs>
              <polygon
                points="70,15 10,50 50,70"
                fill="white"
                stroke-width="2"
                filter="url(#f1)"
              />
            </svg>
            <div className="flex h-auto flex-col p-4 px-6 text-start gap-2 !shadow-login">
              <strong>A senha deve conter:</strong>
              <ul>{verify()}</ul>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between">
          <Button
            size="large"
            className="border-[#212121] text-[#212121] hover:text-red-500 hover:border-red-500 font-semibold rounded px-8"
            onClick={() => {
              reset();
              setModalOpen(false);
            }}
          >
            CANCELAR
          </Button>{" "}
          <Button
            size="large"
            htmlType="submit"
            disabled={
              !passWordValidate?.hasCapitalLetters ||
              !passWordValidate?.hasLowercaseLetter ||
              !passWordValidate?.hasNumbers ||
              !passWordValidate?.numberCharacters ||
              !passWordValidate?.specialCharacters
            }
            className="hover:border-[#212121] hover:text-[#212121] hover:bg-white text-white bg-second font-semibold rounded px-6"
          >
            ATUALIZAR
          </Button>
        </div>
      </form>
    </div>
  );
}
