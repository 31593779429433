/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback } from "react";

import { useState, useEffect } from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Button, Popconfirm } from "antd";
import { formatDate } from "date-fns";
import { FiTrash2 } from "react-icons/fi";
import toast from "react-hot-toast";
import Star from "../../Star/Star";

const ENFORCE_TRUNCATE_TITLE_SIZE = 50;

const processSchema = yup.object().shape({
  id: yup.string().required(),
  process_number: yup.string().required(),
  process_title: yup.string().required(),
  process_description: yup.string().required(),
  labels_primary: yup.string().required(),
});

const ListProcess = ({
  process,
  maxProcessesTitleLength = 50,
  setProcessFavorite,
  deleteProcess,
}) => {
  const [isProcessValid, setIsProcessValid] = useState(false);
  const [processContent, setProcessContent] = useState();

  const parseLabels = ({
    labels_primary,
    labels_secondary,
    nTruncateLabels = 5,
  }) => {
    const primaryLabels = labels_primary.split(",").map((label) => {
      return {
        type: "primary",
        text: label,
      };
    });

    const secondaryLabels = labels_secondary.split(",").map((label) => {
      return {
        type: "secondary",
        text: label,
      };
    });

    const allLabels = [...primaryLabels, ...secondaryLabels];

    const missingLabels = allLabels.length - nTruncateLabels;
    if (missingLabels <= 0 || 5 === -1) {
      return allLabels;
    }

    const missingLabelsObject = {
      type: "truncated_label",
      text: `+${missingLabels}`,
    };

    const truncatedLabels = [
      ...allLabels.slice(0, nTruncateLabels),
      missingLabelsObject,
    ];

    return truncatedLabels;
  };

  const truncateTitle = useCallback(
    (title, nTruncateChars = maxProcessesTitleLength) => {
      if (ENFORCE_TRUNCATE_TITLE_SIZE) {
        nTruncateChars = ENFORCE_TRUNCATE_TITLE_SIZE;
      }

      if (title.length <= nTruncateChars) {
        return title;
      }

      return title.slice(0, nTruncateChars) + "...";
    },
    [maxProcessesTitleLength]
  );

  const handleCopy = useCallback(() => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(processContent?.process_number)
        .then(() => {
          toast.success("Texto copiado para a área de transferência!");
        })
        .catch((err) => {
          toast.error("Erro ao copiar o texto: ", err);
        });
    } else {
      toast.error("A API Clipboard não é suportada pelo navegador.");
    }
  }, [processContent?.process_number]);

  useEffect(() => {
    const validateProcess = async () => {
      const isProcessValid = await processSchema.isValid(process);
      setIsProcessValid(isProcessValid);

      try {
        setProcessContent({
          ...process,
          parsed_labels: parseLabels({
            ...process,
            nTruncateLabels: 5,
          }),
          process_title: truncateTitle(process.process_title),
        });
      } catch (error) {}
    };
    validateProcess();
  }, [process, truncateTitle]);

  return (
    //TODO Original com as constants
    <>
      {isProcessValid ? (
        <div
          className={`md:w-full bg-white flex min-w-[70px] my-3 border border-[#ddd] border-s-[10px] ${
            processContent.is_private
              ? "border-s-[#8B0D0D]"
              : "border-s-[#26203E]"
          } mx-3 md:mx-0 rounded-lg items-center relative flex-wrap  shadow-list gap-3 p-3 justify-center md:p-5 md:items-center md:justify-between`}
        >
          <div
            className={`w-full flex flex-col md:w-[70%] xl:w-[40%] 2xl:w-[30%] md:items-start`}
          >
            <div className="flex gap-3 item-center w-full">
              <img
                className="w-5 h-5 cursor-pointer mb-1"
                src="/img/svg/icons/copyicon.svg"
                onClick={handleCopy}
              />
              <strong className="text-sm md:text-base">
                {processContent.process_number}
              </strong>{" "}
            </div>
            <span className="text-xs md:text-base">
              {processContent.process_title}
            </span>
          </div>
          <div
            className={`h-full flex items-center gap-2 md:gap-4 select-none`}
          >
            <Star process={processContent} />

            <Popconfirm
              title="Deletar processo"
              description="Você tem certeza que deseja deletar este processo?"
              onConfirm={() => {
                deleteProcess(processContent?.id);
              }}
              okText="Confirmar"
              okButtonProps={{
                className: "bg-primary",
              }}
              cancelButtonProps={{
                className: "hover:border-gray-500 hover:text-gray-500",
              }}
              cancelText="Cancelar"
            >
              <FiTrash2
                className="text-xl md:text-3xl cursor-pointer"
                color="#979797"
                id={`popoverButton-${processContent?.id}`}
              />
            </Popconfirm>
          </div>
          {processContent?.last_activity_date ? (
            <span className="w-auto flex font-bold text-sm text-[#858585]">
              {formatDate(processContent?.last_activity_date, "dd/MM/yyyy")}
            </span>
          ) : (
            <span className="w-auto flex font-bold text-sm text-white">
              --/--/----
            </span>
          )}
          <div
            className={`w-full flex-wrap md:w-auto flex md:flex-nowrap gap-3`}
          >
            <Link
              to={process?.private_summary_url}
              className="w-full flex decoration-transparent"
            >
              <Button
                className="w-full xs:text-sm text-base  text-btnPrimary font-semibold px-6 hover:border-borderGray disabled:opacity-85 disabled:text-borderGray"
                disabled={!process?.private_summary_url}
                size="large"
              >
                BAIXAR RESUMO
                <img src="/img/svg/icons/IconDownload.svg" alt="" />
              </Button>
            </Link>
            <Link
              className={`w-full md:w-6/12 justify-center font-bold text-xs border-none decoration-transparent gap-2 rounded-md flex cursor-pointer`}
              to={`/process/${processContent?.id}`}
            >
              <Button
                className="w-full text-xs xs:text-sm bg-purpleLight text-white font-semibold px-6 border-none"
                size="large"
              >
                VISUALIZAR DETALHES
                <img className="w-3" src="/img/svg/icons/icon.svg" />
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div
          className={`mx-3 md:mx-0 md:w-full bg-white flex min-w-[70px] border border-[#ddd] border-s-8 border-s-[#aaaaaa] rounded-lg items-center relative flex-wrap my-3 shadow-list gap-3 p-3 justify-center md:p-5 md:items-center md:justify-between opacity-85`}
        >
          <div
            className={`w-full flex flex-col md:w-[70%] xl:w-[40%] 2xl:w-[30%] md:items-start`}
          >
            <div className="flex gap-3 item-center w-full j">
              <img
                className="w-5 h-5 cursor-pointer mb-1"
                src="/img/svg/icons/copyicon.svg"
                onClick={handleCopy}
              />
              <strong className="text-sm md:text-base">
                {process?.process_number}
              </strong>{" "}
            </div>
            <span className="text-xs md:text-base">
              {process?.process_title}
            </span>
          </div>
          <div className="h-full flex items-center gap-2 md:gap-4 select-none">
            <Star process={processContent} />
            <Popconfirm
              title="Deletar processo"
              description="Você tem certeza que deseja deletar este processo?"
              onConfirm={() => {
                deleteProcess(process?.id);
              }}
              okText="Confirmar"
              okButtonProps={{
                className: "bg-primary",
              }}
              cancelButtonProps={{
                className: "hover:border-gray-500 hover:text-gray-500",
              }}
              cancelText="Cancelar"
            >
              <FiTrash2
                className="text-xl md:text-3xl cursor-pointer"
                color="#979797"
                id={`popoverButton-${process?.id}`}
              />
            </Popconfirm>
          </div>
          {process?.last_activity_date ? (
            <span className="w-auto flex font-bold text-sm text-[#2454c0]">
              {formatDate(process?.last_activity_date, "dd/MM/yyyy")}
            </span>
          ) : (
            <span className="w-auto flex font-bold text-sm text-white">
              --/--/----
            </span>
          )}
          <div
            className={`w-full flex-wrap md:w-auto flex md:flex-nowrap gap-3`}
          >
            <Button
              className={`w-full md:w-6/12 xs:text-sm text-base  font-semibold px-6 hover:border-borderGray`}
              size="large"
              disabled
            >
              BAIXAR RESUMO
              <img
                src="/img/svg/icons/IconDownload.svg"
                alt=""
                className="opacity-35"
              />
            </Button>
            <Link
              className={`w-full md:w-6/12 justify-center font-bold text-xs border-none decoration-transparent gap-2 rounded-md flex cursor-pointer`}
              to={`/process/${process?.id}`}
            >
              <Button
                className="w-full text-xs xs:text-sm  font-semibold px-6 "
                size="large"
                disabled
              >
                VISUALIZAR DETALHES
                <img className="w-3" src="/img/svg/icons/icon.svg" />
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default ListProcess;
