import { Image, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useEffect, useState } from "react";
import { BsUpload } from "react-icons/bs";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function Attachment({
  setValue,
  customUpload,
  data,
  imgProfile,
  setImageProfile,
}) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file?.url || file?.preview);
    setPreviewOpen(true);
  };

  const props = {
    customRequest: customUpload,
    listType: "picture-circle",
    defaultFileList: data && data?.url ? [data] : undefined,
    maxCount: 1,
    multiple: false,
    onRemove() {
      setPreviewImage(undefined);
      setImageProfile(undefined);
      setValue("photo", undefined);
    },
  };

  useEffect(() => {
    if (!imgProfile && data?.url) {
      setImageProfile(data?.url);
      setPreviewImage(data?.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex">
      <ImgCrop rotationSlider quality={1} minZoom={0.5} maxZoom={4}>
        <Upload {...props} onPreview={handlePreview}>
          {!imgProfile && (
            <div
              className="w-full h-full flex items-center justify-center relative"
              data-text="upload_component_file"
            >
              <BsUpload className="max-w-full" size={35} />
            </div>
          )}
        </Upload>
      </ImgCrop>
      {imgProfile && (
        <Image
          className="w-2"
          wrapperStyle={{ display: "none" }}
          data-test="view_image"
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </div>
  );
}
