import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "antd";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import CustomInputMask from "../../CustomInputMask";

import { useNavigate } from "react-router-dom";

export default function RegisterCompany({ setStep, setStepData, stepData }) {
  const navigate = useNavigate();

  const schema = z.object({
    companyName: z
      .string({
        required_error: "Razão social é obrigatório",
      })
      .min(1, "Razão social é obrigatório"),
    photo: z.string().optional(),
    CNPJ: z
      .string({
        required_error: "CNPJ é obrigatório",
      })
      .min(1, "CNPJ é obrigatório"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: stepData ?? undefined,
    mode: "onSubmit",
    resolver: zodResolver(schema),
  });

  const handleFormSubmit = useCallback(
    (data) => {
      setStepData(data);
      setStep(1);
    },
    [setStep, setStepData]
  );

  return (
    <div className="w-full h-fit flex-col  flex justify-center items-center font-poppins m-auto">
      <div className="gap-3 p-5 flex relative flex-col rounded-2xl w-full md:py-32 xs:py-6  px-6 md:px-24">
        <div className="flex items-center ">
          <span className="w-1 h-8 p-1 bg-[#003863] me-4" />
          <h3 className="text-[16px] xs:text-[20px] md:text-2xl font-semibold">
            Crie seu cadastro abaixo
          </h3>
        </div>
        <div className="w-full mt-3">
          <span className="font-normal text-[#40464F]">
            Para o cadastro em nosso sistema é necessário preencher o formulário
            abaixo com os respectivos dados cadastrais. Os Campos com * são de
            preenchimento obrigatório e essenciais para realizarmos o cadastro
          </span>
        </div>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="w-full grid grid-flow-row-dense grid-cols-6 gap-4"
        >
          <div className="col-span-6">
            <span className="text-[#5C5C5C] text-sm font-normal flex">
              CNPJ<p className="text-red-500">*</p>
            </span>
            <Controller
              control={control}
              name="CNPJ"
              render={({ field: { name, onChange, value } }) => (
                <CustomInputMask
                  name={name}
                  style={{
                    resize: "none",
                    borderColor: errors?.CNPJ ? "red" : "",
                  }}
                  mask={"__.___.___/____-__"}
                  replacement={{
                    _: /\d/,
                    X: /[0-9]/,
                  }}
                  dataTestValue="input_cnpj_client"
                  onChange={onChange}
                  value={value}
                  status={errors.CNPJ ? "error" : ""}
                  className={`${
                    errors?.CNPJ
                      ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                      : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                  } w-full rounded-sm `}
                  placeholder={"Insira o seu CNPJ"}
                />
              )}
            />
            {errors?.cpf && (
              <span className="text-[#fe3535] text-sm">
                {errors?.cpf.message}
              </span>
            )}
          </div>
          <div className="col-span-6">
            <span className="text-[#5C5C5C] text-sm font-normal flex">
              Razão social<p className="text-red-500">*</p>
            </span>
            <Controller
              control={control}
              name="companyName"
              render={({ field }) => (
                <Input
                  size="large"
                  {...field}
                  status={errors.companyName ? "error" : ""}
                  placeholder="Insira a razão social"
                  className={`${
                    errors?.companyName
                      ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                      : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                  } w-full rounded-sm `}
                />
              )}
            />
            {errors?.companyName && (
              <span className="text-[#fe3535] text-sm">
                {errors?.companyName.message}
              </span>
            )}
          </div>{" "}
          <div className="col-span-6 flex py-4 gap-16 justify-center">
            {Array.from({ length: 2 }).map((_, index) => (
              <span
                key={index}
                className={`w-4 h-4 rounded-full  ${
                  index <= 0 ? "bg-[#D1CECE]" : "bg-[#F2F1F1]"
                } p-3`}
              />
            ))}
          </div>
          <div className="col-span-6 gap-3 flex flex-col md:flex-row justify-between mt-3">
            <Button
              size="large"
              type="primary"
              className="w-full md:w-auto md:min-w-52 bg-white border-[#003863] text-[#003863] font-bold rounded py-5 hover:opacity-70"
              onClick={() => navigate("/")}
            >
              VOLTAR
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="w-full md:w-auto md:min-w-52 bg-[#C59012] font-bold rounded py-5 hover:opacity-70"
            >
              CONTINUAR
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
