import React, { useCallback, useState } from "react";
import { Button, Input } from "antd";
import { Controller, useForm } from "react-hook-form";
import CustomInputMask from "../../CustomInputMask";
import { zodResolver } from "@hookform/resolvers/zod";
import Attachment from "../../Attachment";

import { z } from "zod";
import { useFindAddressByCepMutation } from "../../../store/services/findCepApi";
import toast from "react-hot-toast";
import { state } from "../../../data/state";
import {
  useCompanyByIDQuery,
  useUpdateCompanyMutation,
} from "../../../store/services/companyApi";
import { useAppSelector } from "../../../store/hooks";
import SkeletonAvatar from "antd/es/skeleton/Avatar";
import { useUploadImageFirmMutation } from "../../../store/services/uploadFileApi";

const schema = z.object({
  id: z.any().optional(),
  name: z.string().min(1, "Nome é obrigatório"),
  CNPJ: z.string().min(1, "CNPJ é obrigatório"),
  photo: z.string().optional(),
  primary_color: z.string().optional(),
  secondary_color: z.string().optional(),
  email: z.string().optional(),
  contact_number: z.string().optional(),
  addressCode: z.string().optional(),
  city: z.string().optional(),
  street: z.string().optional(),
  state: z.string().optional(),
  neighborhood: z.string().optional(),
  number: z.any().optional(),
});

export default function Company() {
  const { userInfo } = useAppSelector((state) => {
    return {
      userInfo: state.userSlice.userData,
    };
  });

  const { data: companyData, isLoading } = useCompanyByIDQuery(
    userInfo.firm_id,
    {
      skip: !userInfo.firm_id,
    }
  );
  const [getAddressInfoByCep] = useFindAddressByCepMutation();

  const [updateCompany] = useUpdateCompanyMutation();
  const [uploadImage] = useUploadImageFirmMutation();

  const [imgProfile, setImageProfile] = useState();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onSubmit",
    resolver: zodResolver(schema),
    values: {
      ...companyData,
      id: companyData?.id,
      CNPJ: companyData?.cnpj?.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        "$1.$2.$3/$4-$5"
      ),
      number: companyData?.number,
      neighborhood:
        companyData?.neighborhood !== "string"
          ? companyData?.neighborhood
          : undefined,
    },
    defaultValues: {
      contact_number: "",
      CNPJ: "",
      addressCode: "",
    },
  });

  const handleFormSubmit = useCallback(
    (data) => {
      const response = updateCompany({
        ...data,
        contact_number: data?.contact_number
          ? data?.contact_number?.replace(/\s+/g, "")
          : null,
      });

      response.then((res) => {
        if (Object.keys(res).includes("error")) {
          toast.error("Erro ao atualizar informações da empresa");
        } else {
          toast.success("Informações atualizadas com sucesso");
        }
      });
    },
    [updateCompany]
  );

  const handleGetAddressInfo = useCallback(
    (cep) => {
      if (cep.length === 9) {
        const response = getAddressInfoByCep(cep);

        response.then((res) => {
          if (Object.keys(res).includes("error")) {
            toast.error("Erro ao buscar informações do cep");
          } else {
            const nameState = state[res?.data?.uf];

            setValue("street", res?.data?.logradouro);
            setValue("city", res?.data?.localidade);
            setValue("neighborhood", res?.data?.bairro);
            setValue("state", nameState);
          }
        });
      }
    },
    [getAddressInfoByCep, setValue]
  );

  const customUploadFile = async ({ file, onSuccess, onError }) => {
    try {
      const result = await uploadImage({
        file,
        id: companyData?.id,
        companyName: companyData?.name,
      }).unwrap();
      onSuccess(result?.picture_url);
      setImageProfile(result?.picture_url);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="w-full p-4 flex flex-col shadow-login gap-7"
    >
      <span className="text-2xl font-semibold underline underline-offset-8">
        Configurações do sistema
      </span>
      <div className="w-auto flex flex-col gap-3 justify-start items-start">
        <span className="font-semibold font-roboto text-base">
          Personalização do sistema
        </span>
        <div className="w-full grid grid-flow-row-dense grid-cols-10 gap-5">
          <div className="col-span-10 md:col-span-2 flex flex-col items-center justify-center gap-2">
            {isLoading ? (
              <SkeletonAvatar size={"large"} />
            ) : (
              <Attachment
                setValue={setValue}
                customUpload={customUploadFile}
                data={{
                  uid: String(companyData?.id),
                  name: companyData?.name,
                  status: "done",
                  url: companyData?.picture_url,
                }}
                imgProfile={imgProfile}
                setImageProfile={setImageProfile}
              />
            )}
            <span className="font-poppins">Logo da empresa</span>
          </div>
          <div className="col-span-10 md:col-span-4 flex flex-col pt-2">
            <span className="text-[#5C5C5C] text-sm font-normal mb-1">
              Cor primária
            </span>
            <div className="flex relative ">
              <Controller
                control={control}
                name="primary_color"
                render={({ field }) => (
                  <Input
                    {...field}
                    size="large"
                    type="color"
                    defaultValue={"#3B71CA"}
                    classNames={{
                      input: "focus:border-[#d9d9d9]",
                    }}
                    className="w-14 p-[2px] border-e-0 rounded-e-none px-2 hover:border-[#d9d9d9] focus:border-[#d9d9d9] active:border-[#d9d9d9] focus-within:border-[#d9d9d9]"
                  />
                )}
              />
              <Controller
                control={control}
                name="primary_color"
                render={({ field }) => (
                  <Input
                    {...field}
                    size="large"
                    placeholder="#3B71CA"
                    className="border-s-0 rounded-s-none p-0 hover:border-[#d9d9d9] flex focus:border-[#d9d9d9] active:border-[#d9d9d9] focus-within:border-[#d9d9d9] focus-within:shadow-none shadow-none:border-s-0 "
                    prefix={
                      <span className="h-6 border-[#C5CAD1] w-1 border-s" />
                    }
                  />
                )}
              />
            </div>
          </div>{" "}
          <div className="col-span-10 md:col-span-4 flex flex-col pt-2">
            <span className="text-[#5C5C5C] text-sm font-normal mb-1">
              Cor secundária
            </span>
            <div className="flex relative ">
              <Controller
                control={control}
                name="secondary_color"
                render={({ field }) => (
                  <Input
                    {...field}
                    size="large"
                    type="color"
                    defaultValue={"#000000"}
                    classNames={{
                      input: "focus:border-[#d9d9d9]",
                    }}
                    className="w-14 p-[2px] border-e-0 rounded-e-none px-2 hover:border-[#d9d9d9] focus:border-[#d9d9d9] active:border-[#d9d9d9] focus-within:border-[#d9d9d9]"
                  />
                )}
              />
              <Controller
                control={control}
                name="secondary_color"
                render={({ field }) => (
                  <Input
                    {...field}
                    size="large"
                    placeholder="#FFFFFF"
                    className="border-s-0 rounded-s-none p-0 hover:border-[#d9d9d9] flex focus:border-[#d9d9d9] active:border-[#d9d9d9] focus-within:border-[#d9d9d9] focus-within:shadow-none shadow-none:border-s-0 "
                    prefix={
                      <span className="h-6 border-[#C5CAD1] w-1 border-s" />
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full grid grid-flow-row-dense grid-cols-6 gap-5">
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal flex mt-[6px]">
            CNPJ<p className="text-red-500">*</p>
          </span>
          <Controller
            control={control}
            name="CNPJ"
            render={({ field: { name, onChange, value } }) => (
              <CustomInputMask
                name={name}
                style={{
                  resize: "none",
                  borderColor: errors?.CNPJ ? "red" : "",
                }}
                mask={"__.___.___/____-__"}
                replacement={{
                  _: /\d/,
                  X: /[0-9]/,
                }}
                dataTestValue="input_cnpj_client"
                onChange={onChange}
                value={value}
                status={errors.CNPJ ? "error" : ""}
                className={`${
                  errors?.CNPJ ? "border-red-600" : ""
                } w-full rounded-sm hover:border-purpleLight focus:border-purpleLight focus:shadow-none h-[39.5px]`}
                placeholder={"Insira o seu CNPJ"}
              />
            )}
          />
          {errors?.cpf && (
            <span className="text-[#fe3535] text-sm">
              {errors?.cpf.message}
            </span>
          )}
        </div>
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">
            Nome fantasia
          </span>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                size="large"
                {...field}
                status={errors.state ? "error" : ""}
                placeholder="Insira o seu Nome"
                className={`${
                  errors?.name
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm`}
              />
            )}
          />
          {errors?.name && (
            <span className="text-[#fe3535] text-sm">
              {errors?.name.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Telefone</span>
          <Controller
            control={control}
            name="contact_number"
            render={({ field: { name, onChange, value } }) => (
              <CustomInputMask
                name={name}
                size="large"
                onChange={onChange}
                value={value}
                mask={"(XX) XXXXX-XXXX"}
                replacement={{
                  _: /\d/,
                  X: /[0-9]/,
                }}
                placeholder="Insira o seu Telefone"
                className={`${
                  errors?.contact_number ? "border-red-600" : ""
                } w-full rounded-sm hover:border-purpleLight focus:border-purpleLight focus:shadow-none h-[39.5px]`}
              />
            )}
          />
          {errors?.contact_number && (
            <span className="text-[#fe3535] text-sm">
              {errors?.contact_number.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">CEP</span>
          <Controller
            control={control}
            name="addressCode"
            render={({ field: { name, value, onChange } }) => (
              <CustomInputMask
                name={name}
                style={{
                  resize: "none",
                }}
                mask={"_____-___"}
                replacement={{
                  _: /\d/,
                  X: /[0-9]/,
                }}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleGetAddressInfo(e.target.value);
                }}
                value={value}
                placeholder="Insira o seu Cep"
                className={`${
                  errors?.addressCode
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.addressCode && (
            <span className="text-[#fe3535] text-sm">
              {errors?.addressCode.message}
            </span>
          )}
        </div>
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Rua</span>
          <Controller
            control={control}
            name="street"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                placeholder="Insira o nome da rua"
                status={errors.street ? "error" : ""}
                className={`${
                  errors?.street
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.street && (
            <span className="text-[#fe3535] text-sm">
              {errors?.street.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Numero</span>
          <Controller
            control={control}
            name="number"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                type="number"
                status={errors?.number ? "error" : "success"}
                placeholder="Insira o número da rua"
                className={`${
                  errors?.number
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.number && (
            <span className="text-[#fe3535] text-sm">
              {errors?.number.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Bairro</span>
          <Controller
            control={control}
            name="neighborhood"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.neighborhood ? "error" : ""}
                placeholder="Insira o nome do bairro"
                className={`${
                  errors?.neighborhood
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.neighborhood && (
            <span className="text-[#fe3535] text-sm">
              {errors?.neighborhood.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Estado</span>
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.state ? "error" : ""}
                placeholder="Insira o nome do Estado"
                className={`${
                  errors?.state
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.state && (
            <span className="text-[#fe3535] text-sm">
              {errors?.state.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 md:col-span-2">
          <span className="text-[#5C5C5C] text-sm font-normal">Cidade</span>
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                status={errors?.city ? "error" : ""}
                placeholder="Insira o Complemento"
                className={`${
                  errors?.city
                    ? "border-red-600 hover:border-red-600 focus:border-red-600 focus:shadow-none "
                    : "hover:border-purpleLight focus:border-purpleLight focus:shadow-none"
                } w-full rounded-sm `}
              />
            )}
          />
          {errors?.city && (
            <span className="text-[#fe3535] text-sm">
              {errors?.city.message}
            </span>
          )}
        </div>{" "}
        <div className="col-span-6 gap-3 md:gap-0 flex flex-col md:flex-row justify-between mt-4">
          {isDirty && (
            <Button
              size="large"
              className="text-[#212121] border-[#212121] hover:text-red-500 hover:border-red-500 font-semibold rounded px-6"
            >
              CANCELAR
            </Button>
          )}
          <div className="flex flex-col md:flex-row gap-3 ms-auto">
            <Button
              size="large"
              htmlType="submit"
              className="text-white bg-second hover:opacity-70 hover:border-[#212121] font-semibold rounded px-6"
            >
              {isDirty ? "ATUALIZAR" : "EDITAR"}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
