import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().loginSlice.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const uploadFileApi = createApi({
  reducerPath: "uploadFileApi",
  baseQuery,
  endpoints: (builder) => ({
    uploadImageFirm: builder.mutation({
      query: ({ file, id, companyName }) => {
        const formData = new FormData();
        formData.append("name", companyName);
        formData.append("firm_img", file);

        return {
          url: `/api/v1/firm/with_pictures/${id}`,
          method: "PUT",
          body: formData,
        };
      },
    }),
    uploadImageUser: builder.mutation({
      query: ({ file, email }) => {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("photo_img", file);

        return {
          url: `/api/v1/users/me`,
          method: "PUT",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useUploadImageFirmMutation,
  useUploadImageUserMutation,
} = uploadFileApi;
