import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().loginSlice.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const processesApi = createApi({
  reducerPath: "processesApi",
  baseQuery,
  tagTypes: ["Processes"],
  endpoints: (builder) => ({
    getProcesses: builder.query({
      query: () => ({
        url: `/api/v1/processes/?skip=0&limit=32`,
      }),
    }),
    getProcessesPagination: builder.mutation({
      query: (page) => ({
        url: `/api/v1/processes/?skip=${(page - 1) * 32}&limit=32`,
      }),
      invalidatesTags: ["Processes"],
    }),
    getProcessesById: builder.query({
      query: (id) => ({
        url: `/api/v1/processes/${id}`,
      }),
      providesTags: ["Processes"],
    }),
    getProcessDocumentsById: builder.query({
      query: (id) => ({
        providesTags: ["Processes"],
        url: `/api/v1/processes/${id}/document-summary/`,
      }),
      providesTags: ["Processes"],
    }),
    getProcessDocumentsInfoById: builder.mutation({
      query: (id) => ({
        url: `/api/v1/processes/${id}/document-summary/`,
      }),
      invalidatesTags: ["Processes"],
    }),
    findProcess: builder.mutation({
      query: (search) => ({
        url: `/api/v1/processes/search_process/?query=${search}`,
      }),
      invalidatesTags: ["Processes"],
    }),
    findDataProcess: builder.mutation({
      query: (data) => ({
        url: `/api/v1/processes/search_process/?query=${data}`,
      }),
      invalidatesTags: ["Processes"],
    }),
    createNewProcess: builder.mutation({
      query: (process) => ({
        url: `api/v1/processes/batch`,
        method: "POST",
        body: process,
      }),
    }),
    invalidatesTags: ["Processes"],
    addProcessFavorite: builder.mutation({
      query: (process) => ({
        url: `api/v1/processes/${process.id}/favorite`,
        method: "PATCH",
      }),
      invalidatesTags: ["Processes"],
    }),
    deleteProcess: builder.mutation({
      query: (id) => ({
        url: `api/v1/processes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Processes"],
    }),
  }),
});

export const {
  useGetProcessesQuery,
  useGetProcessDocumentsByIdQuery,
  useGetProcessDocumentsInfoByIdMutation,
  useFindProcessMutation,
  useFindDataProcessMutation,
  useCreateNewProcessMutation,
  useGetProcessesByIdQuery,
  useAddProcessFavoriteMutation,
  useDeleteProcessMutation,
  useGetProcessesPaginationMutation,
} = processesApi;
