import { Button, Skeleton } from "antd";
import React from "react";
import { useInfoCompanyInviteQuery } from "../../store/services/inviteLinkApi";
import { useParams } from "react-router-dom";

export default function Welcome({ setWelcomeRegister }) {
  const { id, token } = useParams();

  const { data: companyInfo, isLoading } = useInfoCompanyInviteQuery(token, {
    skip: !token,
  });

  return (
    <div className="w-full flex flex-col m-auto items-center md:px-26 gap-8 md:gap-10 ">
      <video
        className="w-full md:max-h-96 flex px-5 md:px-20"
        src="/video/welcome.mp4"
        autoPlay={true}
        loop
        muted
      />

      {id === "firm" ? (
        <>
          <h2 className="font-bold text-[#212121] text-2xl">
            O Futuro do Direito Está Aqui!
          </h2>
          <div className="m-auto px-5 md:px-20 text-xl text-justify md:text-center font-poppins leading-8 flex flex-col max-w-[900px]">
            Descubra como o LexIA pode economizar até 30% do seu tempo na
            análise de processos, enquanto eleva sua prática jurídica a um novo
            patamar de excelência. Essa é a oportunidade de revolucionar sua
            rotina sem custos iniciais!"
          </div>
          <div className="w-full max-w-[700px] px-5 md:px-2">
            <Button
              type="text"
              size="large"
              className="w-full  bg-[#27B43E] font-bold text-white text-2xl hover:opacity-70 font-poppins rounded py-7"
              onClick={() => setWelcomeRegister(false)}
            >
              Teste Gratuitamente
            </Button>
          </div>
        </>
      ) : (
        <>
          <h2 className="font-bold text-[#40464F]">Bem-vindo à Lexia!</h2>

          {isLoading ? (
            <Skeleton className="m-auto px-44" active />
          ) : (
            companyInfo?.firm_name && (
              <div className="m-auto px-4 md:px-20 text-xl text-center font-poppins leading-8 flex  md:flex-col">
                <span className="max-w-xl 2xl:max-w-lg">
                  Você foi convidado(a) para fazer parte do time da{" "}
                  <span className="font-bold">{companyInfo?.firm_name}</span>{" "}
                  Clique no botão abaixo para se juntar a nós e começar agora
                  mesmo.
                </span>
              </div>
            )
          )}
          <Button
            type="text"
            size="large"
            className="bg-[#27B43E] px-6 font-semibold text-white hover:opacity-70"
            onClick={() => setWelcomeRegister(false)}
          >
            CADASTRAR-SE AGORA!
          </Button>
        </>
      )}
    </div>
  );
}
